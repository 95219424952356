<template>
  <div>
    <div class="top_title">
      <div>
        首页 > 会议 > 一键报名
      </div>
    </div>
    <!-- 票种 start -->
    <div class="title_box">
      <i></i>
      <span>选择票种</span>
    </div>
    <div class="ticket_box"
         v-if='ticketList && ticketList.length > 0'>
      <div class="row"
           @click="changeTicket(item.id,item)"
           :class="{row2:item.can_buy == 1 && item.total -  item.buy_num > 0,row3 :item.can_buy != 1 || item.total -  item.buy_num <= 0,active_row:ticketActive == item.id}"
           v-for='(item,index) in ticketList'
           :key='index'>
        <div v-if='ticketActive == item.id '
             class="active"><i class="el-icon-check"></i></div>
        <div class="top">
          <p>{{item.name}}</p>
          <p><span>¥ </span>{{item.amount}}</p>
        </div>
        <p class="status">
          {{item.can_buy == 1 ?'进行中' :item.can_buy == 2 ?'未开始' :item.can_buy == 3 ?'已结束' :'' }}
        </p>
        <!-- can_buy 1 进行中  2 未开始  3 已结束  -->
        <el-popover placement="top-start"
                    v-if='item.explain && item.explain.length > 50'
                    title="说明"
                    width="550"
                    trigger="hover"
                    :content="item.explain">
          <div slot="reference"
               class="bottom ellispTwo">{{item.explain}}</div>
        </el-popover>
        <div class="bottom ellispTwo"
             v-else>
          {{item.explain}}
        </div>
      </div>
    </div>
    <!-- 票种 end -->
    <!-- 报名信息 -->
    <div v-if='ticketActive'
         class="centerWidth marginAuto">
      <div class="title_box">
        <i></i>
        <span>报名信息</span>
      </div>
      <div class="own_info">
        <div class="left">
          <template v-if='hy_ownInfo && hy_ownInfo.lenght > 0 && hy_ownInfo.find(c=>c.key == "name" || c.key == "phone")'></template>
          <div v-for='(item,index) in hy_ownInfo'
               :key='index'>
            <div class="row_text"
                 style=' margin-bottom: 20px;'
                 v-if='item.key == "name" ||item.key == "unit" || item.key == "phone" '>
              <img src="@/assets/img/meeting/icon_19.png"
                   v-if='item.key== "name"'
                   alt="">
              <img src="@/assets/img/meeting/icon_20.png"
                   v-if='item.key== "company"'
                   alt="">
              <img src="@/assets/img/meeting/icon_21.png"
                   v-if='item.key== "phone"'
                   alt="">
              <p>{{item.key== 'name' ?'姓名：' : item.key == 'company' ? '单位：' :'电话：'}} {{item.value}}</p>
            </div>
          </div>
        </div>
        <div class="right">
          <p @click="onEditInfo(-1)"> {{is_ownInfo ? '编辑' : '为我报名'}}</p>
          <!-- <p class="btn1">删除</p> -->
          <!-- <p>为我报名</p> -->
        </div>
      </div>
      <!-- other -->
      <div v-if='is_others == 1'
           class='other_info_box'>
        <div class="title_box">
          <i></i>
          <span>其他参会人</span>
        </div>
        <div v-if='hy_otherInfo && hy_otherInfo.length > 0'
             class="other_info_row">
          <div class="other_info"
               v-for='(row,i) in hy_otherInfo'
               :key='i'>
            <div class="top">
              <template v-if='row && row.lenght > 0 && row.find(c=>c.key == "name" || c.key == "phone")'></template>
              <div v-for='(item,index) in row'
                   :key='index'>
                <div class="row_text"
                     style=' margin-bottom: 20px;'
                     v-if='item.key == "name" ||item.key == "unit" || item.key == "phone" '>
                  <img src="@/assets/img/meeting/icon_19.png"
                       v-if='item.key== "name"'
                       alt="">
                  <img src="@/assets/img/meeting/icon_20.png"
                       v-if='item.key== "unit"'
                       alt="">
                  <img src="@/assets/img/meeting/icon_21.png"
                       v-if='item.key== "phone"'
                       alt="">
                  <p>{{item.key== 'name' ?'姓名：' : item.key == 'unit' ? '单位：' :'电话：'}} {{item.value}}</p>
                </div>
              </div>
            </div>
            <div class="bottom">
              <p @click='onEditInfo(i)'>编辑</p>
              <p class="btn1"
                 @click="onDelete(i)">删除</p>
            </div>
          </div>
        </div>
        <div class="add_box"
             @click="onEditInfo(-2)">添加其他参会人</div>
      </div>
      <!-- invoice start -->
      <div v-if='typeList && typeList.length > 0'>
        <div class="title_box">
          <i></i>
          <span>发票信息</span>
        </div>
        <div class="invoice_box">
          <p>发票信息</p>
          <el-dropdown @command="changeInvoice"
                       trigger="click"
                       v-model="isInvoice">
            <span class="el-dropdown-link"
                  style=' cursor: pointer;'>
              {{isInvoice == 0 ? '无需发票' : invoiceInfo && invoiceInfo.type_text ? invoiceInfo.type_text :'电子发票'}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">无需发票</el-dropdown-item>
              <el-dropdown-item :command="it.key"
                                v-for='(it,i) in typeList'
                                :key='i'>{{it.value}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <!-- invoice end -->
      <div class="bottom_box">
        <div>
          <span v-if='company_discount'>特殊优惠：-¥{{company_discount}}</span>
          <span v-if='morning_bird_discount'>优惠(早鸟票)：-¥{{morning_bird_discount}}</span>
        </div>
        <p class="money">会议费用：<span>¥</span><span>{{amountPayable}}</span></p>
        <el-button :loading='loading'
                   @click="onSave()"
                   class="btn">提交</el-button>
      </div>
    </div>
    <!-- info pop  start-->
    <el-dialog :visible.sync="addInfo.visible"
               :close-on-click-modal='false'
               :show-close='false'
               center
               width="550px">
      <div class="curretInfo_box">
        <div>{{addInfo.type == -1 ?'报名信息' :'为他人报名'}}</div>
        <el-form label-position='left'  label-width='130px'>
          <el-form-item :prop="row.key"
                        v-for='(row,i) in addInfo.curretInfo'
                        :key='i'
                        ref="curretInfo"
                        :label=" row.name +'：'">
            <el-input v-model="row.value"
                      v-if='row.type == "input"'
                      style="width: 320px;height:46px"
                      :placeholder="'请输入' + row.name"></el-input>
            <el-input v-model="row.value"
                      type="textarea"
                      v-else-if='row.type == "textarea"'
                      style="width: 320px;"
                      :placeholder="'请输入' + row.name"></el-input>
            <el-radio-group v-model="row.value"
             style="width: 320px;"
                            v-else-if='row.type == "radio"'>
              <el-radio :label="c"
                        v-for='(c,i) in row.option'
                        :key='i'></el-radio>
            </el-radio-group>
            <el-checkbox-group v-model="row.value"
                              
                               v-else-if='row.type == "checkbox"'>
              <el-checkbox :label="c"
                           :value="c"
                           name="type"
                           v-for='(c,i) in row.option'
                           :key='i'></el-checkbox>
            </el-checkbox-group>
            <el-select v-model="row.value"
                       style="width: 320px;height:46px"
                       v-else-if='row.type == "select"'
                       :placeholder="'请选择' + row.name">
              <el-option v-for="c in row.option"
                         :key="c"
                         :label="c"
                         :value="c"></el-option>
            </el-select>
            <meet-city-option v-model="row.value"
                              v-else-if='row.type == "city" && addInfo.visible' />
          </el-form-item>

        </el-form>
        <el-row class="button_box">
          <el-button class="button button1"
                     @click="addInfo.visible = false">取消</el-button>
          <el-button class="button button2"
                     @click="onSaveInfo()">确定</el-button>
        </el-row>
      </div>
    </el-dialog>
    <!-- info pop  end-->
    <!-- invoice pop start -->
    <el-dialog :visible.sync="invoiceVisible"
               :close-on-click-modal='false'
               :show-close='false'
               center
               width="550px">
      <div class="curretInfo_box">
        <!-- invoiceRules -->
        <div>发票信息</div>
        <el-form label-position='left'
                 :model="invoiceInfo"
                 status-icon
                 ref="invoiceInfo">
          <el-form-item prop='type'
                        key='info1'
                        label="发票类型：">
            <el-radio-group v-model="invoiceInfo.type"
                            @change='changeInvoiceType'>
              <el-radio :label="item.key"
                        v-for='(item,index) in typeList'
                        :key='index'>{{item.value}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop='receive'
                        key='info2'
                        label="领取方式："
                        v-if='invoiceInfo.type != 1'>
            <el-radio-group v-model="invoiceInfo.receive"
                            @change='changeInvoiceReceive'>
              <el-radio :label="item.key"
                        v-for='(item,index) in receiveList'
                        :key='index'>{{item.value}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <!--  -->
          <el-form-item prop="enterpriseName"
                        key='info3'
                        :rules="[
                            { required: true, message: '请输入企业名称', trigger: 'blur' }
                        ]"
                        label="企业名称：">
            <el-input v-model="invoiceInfo.enterpriseName"
                      style="width: 300px;height:46px"
                      placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item prop="enterpriseCode"
                        key='info4'
                        :rules="[
                            { required: true, message: '请输入企业税号', trigger: 'blur' },
                            {pattern:/^([a-z0-9]{10,30})$/i,message: '请输入正确的企业税号',trigger: 'blur'}
                        ]"
                        label="企业税号：">
            <el-input v-model="invoiceInfo.enterpriseCode"
                      style="width: 300px;height:46px"
                      placeholder="请输入企业税号"></el-input>
          </el-form-item>
          <!--  -->
          <el-form-item prop="registerAddress"
                        key='info5'
                        :rules="[
                            { required: invoiceInfo.type != 3 ? false :true, message: '请输入注册地址', trigger: 'blur' }
                        ]"
                        label="注册地址：">
            <el-input v-model="invoiceInfo.registerAddress"
                      style="width: 300px;height:46px"
                      placeholder="请输入注册地址"></el-input>
          </el-form-item>
          <el-form-item prop="registerPhone"
                        key='info6'
                        :rules="[
                            { required: invoiceInfo.type != 3 ? false :true, message: '请输入注册电话', trigger: 'blur' },
                              {pattern:/\-*\d+/,message: '请输入正确的注册电话',trigger: 'blur'}
                        ]"
                        label="注册电话：">
            <el-input v-model="invoiceInfo.registerPhone"
                      style="width: 300px;height:46px"
                      placeholder="请输入注册电话"></el-input>
          </el-form-item>
          <el-form-item prop="bankDeposit"
                        key='info7'
                        :rules="[
                            { required: invoiceInfo.type != 3 ? false :true, message: '请输入开户银行', trigger: 'blur' }
                        ]"
                        label="开户银行：">
            <el-input v-model="invoiceInfo.bankDeposit"
                      style="width: 300px;height:46px"
                      placeholder="请输入开户银行"></el-input>
          </el-form-item>
          <el-form-item prop="bankAccount"
                        key='info8'
                        :rules="[
                            { required: invoiceInfo.type != 3 ? false :true, message: '请输入银行账户', trigger: 'blur' }
                        ]"
                        label="银行账户：">
            <el-input v-model="invoiceInfo.bankAccount"
                      style="width: 300px;height:46px"
                      placeholder="请输入银行账户"></el-input>
          </el-form-item>
          <!--  -->
          <el-form-item prop="email"
                        key='info9'
                        v-if='invoiceInfo.type == 1  || invoiceInfo.type != 1  && invoiceInfo.receive == 2'
                        :rules="[
                            { required: invoiceInfo.type == 1 || invoiceInfo.receive == 2 ? true :false, message: '请输入发票邮箱', trigger: 'blur' },
                            { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
                        ]"
                        label="发票邮箱：">
            <el-input v-model="invoiceInfo.email"
                      style="width: 300px;height:46px"
                      placeholder="请输入发票邮箱"></el-input>
          </el-form-item>
          <el-form-item prop="phone"
                        key='info10'
                        v-if=' invoiceInfo.receive == 2 && invoiceInfo.type != 1'
                        :rules="[
                            { required: true, message: '请输入联系电话', trigger: 'blur' },
                            {pattern: /^1[3-9]\d{9}$/,message: '请输入正确的联系电话',trigger: 'blur'}
                        ]"
                        label="联系电话：">
            <el-input v-model="invoiceInfo.phone"
                      style="width: 300px;height:46px"
                      placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <!--  -->
        </el-form>
        <el-row class="button_box">
          <el-button class="button button1"
                     @click="onInvoiceClose('invoiceInfo')">取消</el-button>
          <el-button class="button button2"
                     @click="onInvoiceSave('invoiceInfo')">确定</el-button>
        </el-row>
      </div>
    </el-dialog>
    <!-- invoice pop end -->
  </div>
</template>

<script>
import meetCityOption from '@/components/meet-city-option.vue'
import { get_ticket_select, get_demand_info, get_invoice_setting, get_discount, generate_order, set_ticketPay } from '@/utils/Api/meeting'
export default {
  components: { meetCityOption },
  props: [],
  data () {
    return {

      loading: false,
      is_others: null,
      meetingId: null,
      ticketList: [],
      ticketActive: null,
      is_ownInfo: false,
      hy_ownInfo: [],
      hy_otherInfo: [],
      curretInfo: [],
      curretInfoKey: [],
      hy_invoice_type: false,
      addInfo: {
        visible: false,
        type: null,
        curretInfo: [],
      },
      isInvoice: 0,
      isOkInvoice: 0,
      invoiceVisible: false,
      typeList: [{ key: 1, value: '电子发票' }, { key: 2, value: '普票' }, { key: 3, value: '专票' }],
      receiveList: [{ key: 1, value: '现场领取' }, { key: 2, value: '邮寄发票' }],
      invoiceInfo: {
        type_text: '电子发票',
        type: 1, // 123
        receive: 1, // 12
        enterpriseName: '',
        enterpriseCode: '',
        registerAddress: '',
        registerPhone: '',
        bankDeposit: '',
        bankAccount: '',
        email: '',
        address: '',
        phone: ''
      },
      company_discount: 0,
      morning_bird_discount: 0,
      orderId: null
    }
  },
  methods: {
    onInvoiceClose () {
      this.invoiceVisible = false;
      if (this.isOkInvoice == 0) {
        this.isInvoice = 0
      } else {
        this.isInvoice = this.isOkInvoice
      }
      this.resetInvoice('invoiceInfo')
    },
    changeInvoice (command) {
      this.isInvoice = command
      if (command != 0) {
        this.invoiceInfo.type = command
        this.invoiceInfo.type_text = this.typeList.find(c => c.key == this.invoiceInfo.type).value
        this.invoiceVisible = true
      }
    },
    async getCompany () {
      var company = this.hy_ownInfo.find(c => c.key == "company" && c.value) ? [this.hy_ownInfo.find(c => c.key == "company").value] : []
      if (this.hy_otherInfo && this.hy_otherInfo.length > 0) {
        this.hy_otherInfo.forEach((row, i) => {
          if (row.find(c => c.key == "company" && c.value)) company.push(row.find(c => c.key == "company").value)
        })
      }
      var res = await get_discount({
        company: company.length > 0 ? company.join(';') : '',
        num: this.hy_otherInfo ? this.hy_otherInfo.length + 1 : 1,
        meet_id: this.meetingId,
        ticket_id: this.ticketActive
      })
      if (res) {
        this.company_discount = res.company
        this.morning_bird_discount = res.morning_bird
      }
    },
    changeInvoiceReceive () {
      this.invoiceInfo.receive_text = this.receiveList.find(c => c.key == this.invoiceInfo.receive).value
    },
    resetInvoice (form) {
      this.$refs[form].resetFields();
    },
    onInvoiceSave (form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          this.$message.success('已添加发票')
          this.isInvoice = this.invoiceInfo.type
          this.isOkInvoice = this.invoiceInfo.type
          this.invoiceVisible = false
        } else {
          return false;
        }
      });
    },
    changeInvoiceType () {
      if (this.invoiceInfo.type == 1) this.invoiceInfo.receive = 1
      this.invoiceInfo.type_text = this.typeList.find(c => c.key == this.invoiceInfo.type).value
    },
    async getTicketList () {
      var res = await get_ticket_select({ meet_id: this.meetingId })
      if (res && res.length > 0) {
        this.ticketList = res
        if (this.ticketList.length > 0 && this.ticketList.find(c => c.can_buy == 1 && (c.total - c.buy_num > 0))) {
          this.ticketActive = this.ticketList.find(c => c.can_buy == 1  && (c.total - c.buy_num > 0)).id
          this.getDemand()
          this.getInvoiceSetting()
        } else {
          this.$message.warning('当前没有票种可以选择了，请参加其他会议！')
        }

      } else {
        this.$message.warning('当前没有票种可以选择了，请参加其他会议！')
        this.$router.go(-1)
      }
    },
    async getInvoiceSetting () {
      var res = await get_invoice_setting({ meet_id: this.meetingId })
      if (res) {
        if (!res.invoice_type || res.invoice_type.length == 0){
            this.typeList =  [];
        }
        if (!res.lingqu_type || res.lingqu_type.length == 0){
            this.receiveList =  [];
        }
        if (!res.invoice_type || res.invoice_type.length == 0 || !res.lingqu_type || res.lingqu_type.length == 0) {
          // this.$message.warning('当前会议不能选取发票')
        } else {
          this.typeList = this.typeList.filter(c => res.invoice_type.find(v => v == c.key))
          this.receiveList = this.receiveList.filter(c => res.lingqu_type.find(v => v == c.key))
          this.invoiceInfo.type_text = this.invoiceInfo.type_text ? this.invoiceInfo.type_text : this.typeList[0].value
          this.invoiceInfo.type = this.invoiceInfo.type ? this.invoiceInfo.type : this.typeList[0].key
          this.invoiceInfo.receive_text = this.invoiceInfo.receive_text ? this.invoiceInfo.receive_text : this.receiveList[0].value
          this.invoiceInfo.receive = this.invoiceInfo.receive ? this.invoiceInfo.receive : this.receiveList[0].key
        }
      } else {
        this.$message.warning('当前会议不能选取发票')
      }
    },
    changeTicket (id, row) {
      if (row.can_buy == 1) {
        if (row.total - row.buy_num > 0) {
          this.ticketActive = id
          this.getCompany()
        } else {
          this.$message.warning(`当前票种已售空`)
        }
      } else {
        this.$message.warning(`当前票种${row.can_buy == 2 ? '未开始' : '已结束'}`)
      }

    },
    onSaveInfo () {
      if (!this.addInfo.curretInfo || this.addInfo.curretInfo.length == 0) this.$message.warning('添加失败')
      var value = null
      var test = null
      var city = null
      for (let i = 0; i < this.addInfo.curretInfo.length; i++) {
        if (!value && this.addInfo.curretInfo[i].is_required && !this.addInfo.curretInfo[i].value) value = this.addInfo.curretInfo[i]
        if (!test && this.addInfo.curretInfo[i].key == 'phone' && this.addInfo.curretInfo[i].value && !(/^1[3-9]\d{9}$/.test(this.addInfo.curretInfo[i].value))) {
          test = '请输入正确的电话号码！'
        }
        if (!city && this.addInfo.curretInfo[i].key == 'city' && (!this.addInfo.curretInfo[i].value || this.addInfo.curretInfo[i].value.split(',').length != 3)) {
          city = this.addInfo.curretInfo[i].value ? `${this.addInfo.curretInfo[i].name}填写不完整` : `${this.addInfo.curretInfo[i].name}是必填字段`
        }
      }
      if (value) return this.$message.warning(`${value.name}是必填字段`)
      if (city) return this.$message.warning(city)
      if (test) return this.$message.warning(test)
      var info = JSON.parse(JSON.stringify(this.addInfo.curretInfo))
      info.forEach(c => {
        if (c.type == 'checkbox' && c.value) c.value = c.value.join(',')
      })
      if (this.addInfo.type == -1) {
        this.is_ownInfo = true

        this.hy_ownInfo = info
      } else if (this.addInfo.type == -2) {
        this.hy_otherInfo.push(info)
      } else {
        this.hy_otherInfo.splice(this.addInfo.type, 1, info)
      }
      this.getCompany()
      this.addInfo.visible = false
    },
    onEditInfo (x) {
      this.addInfo.type = x
      if (x == -1) {
        let info = JSON.parse(JSON.stringify(this.hy_ownInfo))
        info.forEach(c => {
          if (c.type == 'checkbox' && c.value) c.value.length > 0 ? c.value = c.value.split(',') : c.value = []
        })
        this.addInfo.curretInfo = info
      } else if (x == -2) {
        let info = JSON.parse(JSON.stringify(this.curretInfo))

        info.forEach(c => {
          if (c.type == 'checkbox' && c.value) c.value.length > 0 ? c.value = c.value.split(',') : c.value = []
        })
        this.addInfo.curretInfo = info
      } else {
        let info = JSON.parse(JSON.stringify(this.hy_otherInfo[x]))
        info.forEach(c => {
          if (c.type == 'checkbox' && c.value) c.value.length > 0 ? c.value = c.value.split(',') : c.value = []
        })
        this.addInfo.curretInfo = info
      }
      this.addInfo.visible = true
    },
    onDelete (i) {
      this.$confirm('您确认删除当前参会人吗？', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() => {
        this.hy_otherInfo.splice(i, 1)
        this.$message.success('已删除')
      }).catch(() => { })
    },
    async getDemand () {
      var res = await get_demand_info({ meet_id: this.meetingId, ticket_id: this.ticketActive })
      if (res) {
        var list = []
        list = res.join_table
        list.forEach(it => it.value = it.type == 'checkbox' ? [] : null)
        this.curretInfo = JSON.parse(JSON.stringify(list))
        this.hy_ownInfo = JSON.parse(JSON.stringify(list))
        this.hy_ownInfo.forEach((it, i) => {
          if (this.myInfo.hasOwnProperty(it.key)) {
            it.value = it.key == 'name' ? '' : this.myInfo[it.key]
          }
          if (it.key == 'name') {
            this.myInfo.real_name ? it.value = this.myInfo['real_name'] : ''
          }
          if (it.key == 'sex') {
            this.myInfo.sex ? it.value = this.myInfo.sex == 1 ? '男' : '女' : ''
          }
          if (it.key == 'company') {
            it.value = this.myInfo.unit ? this.myInfo.unit : ''
          }
          if (it.key == 'company') {
            it.value = this.myInfo.unit ? this.myInfo.unit : ''
          }
          if (it.key == 'city') {
            if (this.myInfo.province_id) {
              it.value = [this.myInfo.province_id, this.myInfo.city_id, this.myInfo.zone_id].join(',')
            } else {
              it.value = ''
            }
          }
        })
        // is_ownInfo
        this.is_ownInfo = this.hy_ownInfo.findIndex(c => c.is_required && !c.value) == -1 ? true : false
        this.getCompany()
      }
    },
    async onSave () {
      if (!this.is_ownInfo) return this.$message.warning('请完善您自己的报名信息！')
      this.loading = true
      var user_info = JSON.stringify({
        own_info: this.hy_ownInfo,
        other_info: this.hy_otherInfo
      });
      var params = {
        amount: this.amountPayable,
        ticket_id: this.ticketActive,
        user_info,
        company: this.company_discount,
        morning_bird: this.morning_bird_discount,
        pay_type: Number(this.amountPayable) <= 0 ? 5 : 2,
        invoice: this.isInvoice != 0 && Object.keys(this.invoiceInfo).length > 0 ? JSON.stringify(this.invoiceInfo) : null,
        is_invoice: this.isInvoice == 0 ? 2 : 1,
        duan_type: 2
      }
      try {
        var res = await generate_order(params)
        if (res) {
          if (Number(this.amountPayable) <= 0) {
            let params = {
              join_meet_id: res,
              amount: this.amountPayable,
              pay_type: 5,
              duan_type: 2,
              ticket_id: this.ticketActive,
            }
            let res2 = await set_ticketPay(params)
            if (res2) {
              this.$message.success('免单成功')
              this.$router.replace('/personal/meetingOrder')
            } else {
              this.$router.replace('/personal/meetingOrder')
            }
          } else {
            this.$router.replace({ path: '/onlinePayment', query: { type: 1, orderId: res } })
          }
        }
        this.loading = false
      } catch{
        this.loading = false
      }

    },
  },
  created () {
    this.meetingId = this.$route.query.meetingId
    this.is_others = this.$route.query.is_others
    this.getTicketList()
  },
  mounted () {
  },
  destroyed () { },
  computed: {
    myInfo () {
      return this.$store.getters.personal
    },
    amountPayable () {
      var ticket = this.ticketList.find(c => c.id == this.ticketActive) ? this.ticketList.find(c => c.id == this.ticketActive).amount : 0
      var money1 = this.$global.formatFloat(ticket, (this.hy_otherInfo ? this.hy_otherInfo.length + 1 : 1), 3)
      var money = this.$global.formatFloat(money1, this.$global.formatFloat(this.company_discount, this.morning_bird_discount, 1), 2)
      return money > 0 ? money : 0
    }
  },
  watch: {
    myInfo (x, i) {
      if (!x) {
        this.$message.warning('请登录后在参加报名')
        this.$router.push('/')
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.top_title {
  background-color: #fff;
  border-top: 3px solid #47d7e3;
  > div {
    height: 60px;
    line-height: 60px;
    text-align: left;
    width: 1200px;
    margin: 0 auto;
    font-size: 14px;
    color: #666;
  }
}
.title_box {
  text-align: left;
  i {
    width: 4px;
    height: 20px;
    margin-right: 16px;
    display: inline-block;
    background-color: #47d7e3;
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
  font-size: 20px;
  line-height: 20px;
  color: #333;
  width: 1200px;
  margin: 32px auto 16px;
}
.ticket_box {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  .active_row {
    border: 1px solid #47d7e3;
  }
  .active {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 35px solid #47d7e3;
    border-left: 35px solid transparent;
    color: #fff;
    text-align: center;
    i {
      left: 0;
      position: absolute;
      color: #fff;
      font-weight: 700;
      top: 17px;
      left: -17px;
    }
  }
  .row2 {
    &:hover {
      padding: 25px 20px;
      border: 1px solid #47d7e3;
    }
  }
  .row {
    width: 592px;
    min-height: 139px;
    background: #ffffff;
    border-radius: 6px;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid #fff;
    box-shadow: 0px 9px 10px 0px rgba(221, 221, 221, 0.16);
    position: relative;
    &:nth-child(odd) {
      margin-right: 16px;
    }
    .status {
      font-size: 16px;
      line-height: 16px;
      text-align: left;
      margin-bottom: 10px;
      color: #333;
    }
    margin-bottom: 16px;
    padding: 25px 20px;
    .bottom {
      text-align: left;
      font-size: 16px;
      line-height: 28px;
      border: 0;
      padding: 0;
      width: 100%;
    }
    .el-button:hover,
    .el-button:focus {
      color: #333;
      border: 0;
      background-color: #fff;
    }
    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      > p {
        text-align: left;
        font-size: 18px;
        line-height: 25px;
      }
      > p:nth-child(1) {
        width: calc(100% - 200px);
        color: #333;
        font-weight: 700;
      }
      > p:nth-child(2) {
        text-align: right;
        width: 200px;
        color: #ff0036;
        font-weight: 700;
        span {
          font-size: 12px;
        }
      }
    }
  }
  .row3 {
    .status {
      color: #999;
    }
    .top {
      > p:nth-child(1) {
        color: #666;
      }
      > p:nth-child(2) {
        color: #666;
      }
    }
  }
}
.own_info {
  width: 592px;
  height: 159px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 9px 10px 0px rgba(221, 221, 221, 0.16);
  padding: 24px 20px;
  font-size: 18px;
  color: #333;
  display: flex;
  justify-content: space-between;
  img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    vertical-align: middle;
  }
  .left {
    width: calc(100% - 125px);
  }
  .row_text {
    margin-bottom: 20px;
    text-align: left;
    line-height: 25px;
    vertical-align: middle;
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
    > p {
      vertical-align: middle;
      display: inline-block;
      width: calc(100% - 50px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .btn1 {
      color: #909191;
      border: 1px solid #c6c6c6;
      background-color: #fff;
    }
    > p {
      cursor: pointer;
      width: 112px;
      height: 42px;
      line-height: 42px;
      color: #fff;
      box-sizing: border-box;
      background: #47d7e3;
      border-radius: 2px;
      text-align: center;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.other_info_box {
  .add_box {
    width: 144px;
    height: 42px;
    color: #fff;
    background: #47d7e3;
    border-radius: 2px;
    font-size: 16px;
    line-height: 42px;
    text-align: center;
    cursor: pointer;
  }
  .other_info_row {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .other_info {
    margin-right: 16px;
    margin-bottom: 16px;
    &:nth-child(4n + 4) {
      margin-right: 0px;
    }
    width: 288px;
    height: 212px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 9px 10px 0px rgba(221, 221, 221, 0.16);
    padding: 24px 20px;
    font-size: 18px;
    color: #333;
    img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      vertical-align: middle;
    }
    .row_text {
      margin-bottom: 20px;
      text-align: left;
      line-height: 25px;
      vertical-align: middle;
      &:last-child {
        margin-bottom: 0;
      }
      > p {
        vertical-align: middle;
        display: inline-block;
        width: calc(100% - 50px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .bottom {
      margin-top: 33px;
      display: flex;
      justify-content: flex-end;
      .btn1 {
        color: #909191;
        border: 1px solid #c6c6c6;
        background-color: #fff;
      }
      > p {
        font-size: 14px;
        cursor: pointer;
        width: 60px;
        height: 28px;
        line-height: 28px;
        color: #fff;
        box-sizing: border-box;
        background: #47d7e3;
        border-radius: 2px;
        text-align: center;
        margin-right: 8px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
.bottom_box {
  width: 1200px;
  margin: 55px auto 0;
  padding: 0 32px;
  box-sizing: border-box;
  height: 73px;
  background: #ffffff;
  box-shadow: 0px 9px 10px 0px rgba(221, 221, 221, 0.16);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > div {
    margin-right: 30px;
    font-size: 16px;
    span {
      padding-left: 20px;
      color: #999;
    }
  }
  .el-button {
    border: 0;
  }
  .money {
    color: #333;
    font-size: 18px;
    span:nth-child(1) {
      color: #ff0036;
      font-weight: 700;
      font-size: 12px;
    }
    span:nth-child(2) {
      color: #ff0036;
      font-weight: 700;
      font-size: 18px;
    }
  }
  .btn {
    margin-left: 24px;
    font-size: 16px;
    color: #fff;
    width: 112px;
    height: 42px;
    background: #f03708;
    border-radius: 2px;
    border: 1;
  }
}
.curretInfo_box {
  /deep/ .el-form-item__label {
    font-size: 18px;
    color: #19191b;
  }
  /deep/ .el-radio{
    margin-bottom:5px
  }
  padding: 0 20px;
  > div:nth-child(1) {
    text-align: center;
    font-size: 20px;
    line-height: 32px;
    color: #333;
    margin-bottom: 32px;
    font-weight: 700;
  }
  .el-input__inner {
    height: 46px;
  }
  .button_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 54px;
    .button {
      width: 168px;
      height: 46px;
      background: #ffffff;
      border: 1px solid #c6c6c6;
      border-radius: 2px;
      color: #979797;
      font-size: 16px;
    }
    .button2 {
      border: 1px solid #47d7e3;
      background-color: #47d7e3;
      color: #fff;
    }
  }
}
/deep/.el-form-item__label {
  width: 120px;
  text-align: right;
}
// /deep/ .el-radio__input.is-checked .el-radio__inner {
//   border-color: #47d7e3;
//   background: #47d7e3;
// }
// /deep/ .el-radio__label {
//   color: #47d7e3;
// }
// /deep/.el-radio__input.is-checked + .el-radio__label {
//   color: #47d7e3;
// }
.invoice_box {
  width: 592px;
  padding: 0 20px;
  box-sizing: border-box;
  height: 73px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 9px 10px 0px rgba(221, 221, 221, 0.16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    font-size: 18px;
    color: #333;
  }
}
</style>
