<template>
  <div>
    <addressSelect :provice="'org'"
                   ref="orgInfoAddress"
                   @proviceSE='proviceSE'></addressSelect>
  </div>
</template>
 
 <script type="text/javascript">
import addressSelect from '@/components/addressSelectup'
export default {
  name: "",
  model: {
    prop: 'active',
    event: 'change'
  },
  props: {
    tips: {
      type: String,
      defalut: ''
    },
    active: {
      type: String,
      defalut: ''
    },
  },
  components: { addressSelect },
  data () {
    return {
      activeSon: this.active ? this.active.split(',').map(c => parseInt(c)) : [],
      activeValue: '',
    }
  },
  async created () {

  },
  mounted () {
    // if (this.activeSon && this.activeSon.length > 0) {
    this.$refs.orgInfoAddress.assignaddressbinds(this.activeSon)
    // }

  },
  methods: {
    proviceSE (v) {
      var arr = []
      if (v[0]) {
        arr.push(v[0])
        if (v[1]) {
          arr.push(v[1])
          if (v[2]) {
            arr.push(v[2])
          }
        }
      }
      this.$emit('change', arr.join(','))
    },
    // proviceSEV (v) {
    //   if (v && v.length > 0) {
    //     this.activeValue = v.join(',')
    //   }
    //   this.$emit('change', this.activeValue)
    // },

  }
};
</script>
<style lang="scss" scoped>
/deep/ .addressSelect {
  width: auto;
  justify-content: flex-start !important;
}
.right_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .row1 {
    .icon {
      vertical-align: middle;
    }
    margin-right: 40rpx;
    &:last-child {
      margin: 0;
    }
  }
}
text {
  margin-left: 16rpx;
  font-size: #333;
}
.text2 {
  color: #999;
}
.right_box2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
